import { createAnalytics, plugins } from '@ffn/cx-event-horizon/browser'
import Cookies from 'js-cookie'
import { ANALYTICS } from 'constants/analytics'
import { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { FEATURE_TOGGLES } from 'constants/featureToggles'

const isDebug = process.env.NODE_ENV === 'production' ? false : true // or set with an environment variable

const baseObject = {
  event_origin: 'client',
}

const analyticsInstance = createAnalytics({
  application: ANALYTICS.APP_NAME,
  debug: isDebug,
  plugins: [
    plugins.analyticsFreedom({
      application: ANALYTICS.APP_NAME,
      isDebug: isDebug,
    }),
    plugins.analyticsTealium({
      application: ANALYTICS.APP_NAME,
    }),
  ],
})

const kebabCaseToPascalCase = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const objectToQueryString = (obj) => {
  if (!obj) return ''
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

const getPagePath = (pathname) => {
  if (pathname === '/') return pathname
  // remove proceeding slash and ensure pathname does not include query string
  return pathname.substring(1).split('?')[0]
}

const waitForTealium = (fn) => {
  // ACX-145: Delay page track so Tealium data is present
  // It would be better to queue these events in the AnalyticsProvider,
  // but we need a way to watch window.utag.data.tealum_session_id
  // Yes we could queue until the next event or page render but what if there isn't one
  const tealium_session_id = window?.utag?.data?.tealium_session_id
  let delay = 2500
  if (tealium_session_id) {
    delay = 0
  }
  const timer = setTimeout(() => {
    fn()
  }, delay)
  return () => clearTimeout(timer)
}

const deDupeQueryParams = async (params) => {
  if (!params) return ''

  /*  This function would be called every time a route wiht query params is called,
      it uses an API as is the Highest order component and the Feature Context wouldn't provide for it
      would be there a way to acces pageProps here without runing getServerSideProps?
      or either polluting PageHandler prop.

      If there is a better way to do this, it should be adressed.
  */
  const parseUrl = await fetch('/api/launchDarkly/getFeatureFlag', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ flag: FEATURE_TOGGLES.ACX_WEB_ENABLE_UTM_PARSE }),
  }).then((r) => r.json())

  return !parseUrl.status
    ? params
    : params
        .split('&')
        .map((param) => {
          let decodedParam = decodeURIComponent(param)
          let value = decodedParam.split('=')[1].split(',')[0]
          let key = decodedParam.split('=')[0]
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        })
        .join('&')
}

const constructTrackingObject = (
  pagePath,
  analyticsEnv,
  referer,
  urlParams,
  host,
  fullPagePath
) => {
  const page_name = `${ANALYTICS.APP_NAME} | ${
    pagePath === '/' ? 'Homepage' : kebabCaseToPascalCase(pagePath)
  }`
  return {
    page_name,
    event_name: `${page_name} - Page Viewed`,
    profile_id: Cookies.get(ANALYTICS.HUB_PROFILE_ID_COOKIE_NAME),
    session_id: sessionStorage.getItem(ANALYTICS.ACHIEVE_SESSION_KEY_NAME),
    application_version: analyticsEnv,
    referring_url: referer,
    url_params: deDupeQueryParams(urlParams),
    page_title: pagePath === '/' ? 'Homepage' : kebabCaseToPascalCase(pagePath),
    page_path: `${ANALYTICS.APP_NAME} | ${pagePath}`,
    page_url: `https://${host}/${pagePath}`,
    full_page_url: `https://${host}${fullPagePath}`,
  }
}

/**
 * ACX-194
 *
 * Handle track and react events
 * This allows you to have a track prop and/or onClick prop
 * If you have both a track and onClick prop,
 * they will be called in parallel
 *
 * If track is a boolean then the default track behavior will be used
 * If track is a function then the function will be called with the event
 * If track is an object then the object will override event and return,
 * and override or add additional properties
 * @param {SyntheticBaseEvent} e - click event
 * @param {*} track - track prop, boolean, function, or object
 * @param {Object} reactEvent - any react event such as onClick
 */
const handleTrackAndReactEvent = (e, track, reactEvent) => {
  if (typeof reactEvent === 'function') {
    reactEvent(e)
  }

  if (typeof track === 'boolean' && track === true) {
    return e
  } else if (typeof track === 'function') {
    return track(e)
  } else if (typeof track === 'object') {
    return { ...track }
  }
}

const trackEvent = ({
  ids,
  pagePath,
  fullPagePath,
  urlParams,
  referer,
  analyticsEnv,
  profile,
  experiments,
  host,
  ...application_data
}) => {
  const {
    page_name,
    event_name,
    profile_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(pagePath, analyticsEnv, referer, urlParams, host, fullPagePath)

  analyticsInstance.track('click', {
    ...baseObject,
    event_action: 'click',
    page_name,
    event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      profile_id,
      session_id,
    },
    profile,
    experiments,
  })
}

const trackPage = ({
  ids,
  pagePath,
  fullPagePath,
  urlParams,
  referer,
  analyticsEnv,
  profile,
  experiments,
  host,
  ...application_data
}) => {
  const {
    page_name,
    event_name,
    profile_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(pagePath, analyticsEnv, referer, urlParams, host, fullPagePath)

  analyticsInstance.page({
    ...baseObject,
    event_action: 'page_view',
    // event-horizon v0.4.3
    // page_name ends up application_data but you can't specify it in application_data
    // if you do you will see ANALYTICS :: freedom :: page -  undefined - Page Loaded
    page_name,
    event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      profile_id,
      session_id,
    },
    profile,
    experiments,
  })
}

const useTrackEvent = () => {
  const { state, dispatch } = useContext(AnalyticsContext)

  useEffect(() => {
    //Nothing happens if there are no events.
    if (!(state.eventQueue.length > 0)) return

    if (!state.isLoaded) return

    trackEvent({ ...state.trackingData, ...state.eventQueue[0] })
    dispatch({ type: 'REMOVE_LAST_EVENT_FROM_QUEUE' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.eventQueue])
}

const useTrackPage = ({ analyticsEnv, referer, host }) => {
  const { dispatch } = useContext(AnalyticsContext)

  const router = useRouter()
  // remove proceeding slash and ensure pathname does not include query string
  const pagePath = getPagePath(router.pathname)
  const urlParams = objectToQueryString(router.query)
  const { asPath: fullPagePath } = router

  useEffect(() => {
    dispatch({ type: 'SET_PAGE_STATE_LOADING', payload: { isLoaded: false } })

    waitForTealium(() => {
      trackPage({ analyticsEnv, referer, host, pagePath, urlParams, fullPagePath })

      dispatch({
        type: 'SET_PAGE_STATE_LOADED',
        payload: {
          isLoaded: true,
          trackingData: { analyticsEnv, referer, host, pagePath, urlParams, fullPagePath },
        },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsEnv, referer, host, pagePath, urlParams, fullPagePath])
}

// Preserve default behavior
export default analyticsInstance
// But these are the functions to use
export { trackEvent, trackPage, useTrackPage, useTrackEvent, handleTrackAndReactEvent }
