import App from 'next/app'
import { StyledEngineProvider, SunbeamThemeProvider } from '@ffn/sunbeam'
import CssBaseline from '@mui/material/CssBaseline'
import Layout from 'components/Layout'
import { CacheProvider } from '@emotion/react'
import { createEmotionCache } from 'utils/emotion'
import { AnalyticsProvider } from 'providers/AnalyticsProvider'
import { FeatureFlagProvider } from 'providers/FeatureFlagProvider'

// Using global styling to apply style to third-party components
import './global.scss'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  referer,
  publicProcessDotEnv,
  pageProps,
  host,
}) {
  return (
    <AnalyticsProvider publicProcessDotEnv={publicProcessDotEnv} referer={referer} host={host}>
      <CacheProvider value={emotionCache}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <SunbeamThemeProvider>
            <FeatureFlagProvider featureToggleObject={pageProps.featureToggleObject || {}}>
              <Layout
                pageMetaData={pageProps?.content?.fields?.pageMetaData}
                pageMetaTitle={pageProps?.content?.fields?.pageMetaTitle}
                canonicalLink={
                  pageProps?.content?.fields?.canonicalLinkOverride || pageProps.completePageUrl
                }
                pageDisclosure={pageProps?.content?.fields?.pageDisclosure}
                featureToggleObject={pageProps.featureToggleObject}
              >
                <Component {...pageProps} />
              </Layout>
            </FeatureFlagProvider>
          </SunbeamThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </AnalyticsProvider>
  )
}

const getObjectOfPublicEnvs = () => {
  // Get properties from process.env that have keys that start with NEXT_PUBLIC_
  const publicEnvs = Object.entries(process.env).filter(([key]) => key.startsWith('NEXT_PUBLIC_'))
  // Return an object with the keys and values of the public envs
  return publicEnvs.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

// This is required to inject environment variables client side after build time.
// NOTE that this also disables automatic static optimization
MyApp.getInitialProps = async (props) => {
  // Get tealium URL from env var. Typically this would be inlined at build time but we do a single build.
  const publicProcessDotEnv = getObjectOfPublicEnvs()
  const referer = props?.ctx?.req?.headers?.referer
  const initProps = await App.getInitialProps(props)
  const host = props?.ctx?.req?.headers?.host
  // Return our initial props and our new props
  return { ...initProps, publicProcessDotEnv, referer, host }
}

export default MyApp
