import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import styles from './Navigation.module.scss'
import MainNavItem from './MainNavItem'
import useActiveNavItemIndex from 'hooks/useActiveNavItemIndex'

/**
 * @param {{
 *  condensed: boolean|false
 *  disabledRoutes: []
 *  items: []
 *  mobileNavOpen: boolean|false
 * }} props
 */
export default function Navigation({
  condensed = false,
  mobileNavOpen = false,
  items,
  disabledRoutes = [],
  disabledElements = [],
}) {
  let [activeItemIndex] = useActiveNavItemIndex()
  const router = useRouter() || {}

  return (
    <nav
      className={styles['main-nav']}
      data-condensed={condensed}
      data-mobile-nav-open={mobileNavOpen}
      data-subnav-open={activeItemIndex >= 0}
    >
      <ul>
        {items.map((props, i) => (
          <MainNavItem
            itemIndex={i}
            key={i}
            currentPathname={router.pathname}
            {...props}
            disabledRoutes={disabledRoutes}
            disabledElements={disabledElements}
          />
        ))}
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  condensed: PropTypes.bool,
  disabledRoutes: PropTypes.array,
  disabledElements: PropTypes.object,
  mobileNavOpen: PropTypes.bool,
  items: PropTypes.array.isRequired,
}
