import { IconButton } from '@ffn/sunbeam'
import { ChevronUp } from 'react-feather'
import { scrollToTop } from 'utils/shared'

function ScrollToTopButton(props) {
  return (
    <IconButton aria-label="Scroll to the top of the page" onClick={scrollToTop} {...props}>
      <ChevronUp />
    </IconButton>
  )
}
export default ScrollToTopButton
