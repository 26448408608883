import { Image } from 'components/Image'
//import Image from 'next/image'

export function MediaImage({
  content,
  alt,
  width = '100%',
  height = '100%',
  layout = 'responsive',
  objectFit = 'contain',
  ...restProps
}) {
  const { file, title } = content?.fields || {}
  const urlWithProtocol = file?.url ? `https://${file.url}` : ''
  if (layout === 'fill') {
    return (
      <Image
        src={urlWithProtocol}
        alt={alt || title}
        layout={layout}
        objectFit={objectFit}
        {...restProps}
      />
    )
  }
  return (
    <Image
      src={urlWithProtocol}
      alt={alt || title}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      {...restProps}
    />
  )
}
