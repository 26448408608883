import Container from '@mui/material/Container'
import styles from './Section.module.scss'
import classNames from 'classnames'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'

function Section({
  children,
  backgroundColor,
  childContainerClassName,
  className,
  contain = true,
  ...containerProps
}) {
  const isNarrowContent = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_NARROW_CONTENT)

  const contents = contain ? (
    <Container
      maxWidth={false}
      disableGutters
      className={classNames(styles['achieve-section-container'], childContainerClassName)}
      data-narrow={isNarrowContent}
      {...containerProps}
    >
      {children}
    </Container>
  ) : (
    children
  )

  return (
    <section style={{ backgroundColor }} className={className}>
      {contents}
    </section>
  )
}

export default Section
