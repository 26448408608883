import { Image } from 'components/Image'
import { get as _get } from 'lodash-es'
import styles from './MediaImageStandard.module.scss'

/**
 * Creates JSX Element that displays image
 *
 * USAGE:
 *  Option 1 & 2: layout == 'fill' -> Image will scale down to fit containers dimensions but not more than original image dimensions
 *  Option 1: objectFit == 'cover' -> image fills container cropped to container aspect ratio
 *  Option 2: objectFit == 'contain' -> image fits fully inside container scales to first axis to hit container boundary
 *
 *  Option 3: Keep image aspect ratio and allow image to be any size to fit responsive container.
 *
 * All options:
 * 		- Style border radius on MediaImageStandard element
 * 		- Padding/Margin must be set to an additional container around the MediaImageStandard.
 *
 * @param {Object} content - Required, the image content object from Contentful
 * @param {string} [alt] - Optional but recommended, image alt attribute info
 * @param {number|string} [width=undefined] - Use with height if Layout='fill' and want fixed size
 * @param {number|string} [height=undefined] - Use if Layout='fill' and want fixed image height
 * @param {string} [layout='responsive'] - Either empty or 'fill'
 * @param {string} [objectFit='cover'] - Optional (only affects layout='fill')
 * @param {string} [objectPosition='center'] - Optional (only affects layout='fill')
 * @returns JSX
 */
export function MediaImageStandard({
  content,
  alt,
  width = undefined,
  height = undefined,
  layout = 'responsive',
  objectFit = 'cover',
  objectPosition = 'center',
  ...restProps
}) {
  const { file, title } = content?.fields || {}
  const urlWithProtocol = file?.url ? `https://${file.url}` : ''

  //Get original image dimensions if width or height not provided as argument
  if (layout === 'responsive') {
    height = _get(file, 'details.image.height')
    width = _get(file, 'details.image.width')
  } else if (width === undefined) {
    width = objectFit === 'cover' ? '100%' : _get(file, 'details.image.width')
  } else if (height === undefined) {
    height = objectFit === 'cover' ? '100%' : _get(file, 'details.image.height')
  }

  //Option 1 & 2:
  if (layout === 'fill') {
    if (typeof width === 'number') width = `${width}px`
    if (typeof height === 'number') height = `${height}px`

    return (
      <div
        className={
          objectFit === 'contain'
            ? styles['image-container-fit-contain']
            : styles['image-container-fit-cover']
        }
        style={{
          position: 'relative',
          width: width,
          height: height,
        }}
        data-testid="media-image-container"
      >
        <Image
          src={urlWithProtocol}
          alt={alt || title}
          layout={layout}
          objectFit={objectFit}
          objectPosition={objectPosition}
          {...restProps}
        />
      </div>
    )
  }

  //Option 3:
  return (
    <Image
      src={urlWithProtocol}
      alt={alt || title}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
      {...restProps}
    />
  )
}
