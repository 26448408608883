import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Image } from 'components/Image'
//import Image from 'next/image'

const richTextLinkToComponentMapper = (linkText) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <span>{children}</span>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <Image
            src={`https:${node.data.target.fields.file.url}`}
            height={10}
            width={10}
            alt={linkText.content[0].content[0].value}
            priority={true}
          />
        )
      },
    },
    renderText: (text) => text.replace('!', '?'),
  }
  return documentToReactComponents(linkText, options)
}

export function Link({ content, track }) {
  if (Object.prototype.hasOwnProperty.call(content, 'fields')) {
    const { linkText, linkHref } = content?.fields || {}
    return (
      <AchieveLink track={typeof track === 'boolean' ? `${track}` : track} href={linkHref}>
        {richTextLinkToComponentMapper(linkText)}
      </AchieveLink>
    )
  }
  return null
}
