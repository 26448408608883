import Vimeo from '@u-wave/react-vimeo'
import { Grid, Skeleton } from '@ffn/sunbeam'
import styles from './VideoPlayer.module.scss'

import React, { useReducer, useEffect, useState } from 'react'
import useViewPortSize from 'hooks/useViewportSize'
import { videoMap } from 'constants/videoMappings'
import { iOSSafariMobile } from 'utils/shared'
import useIsSsr from 'hooks/useIsSsr'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'initVideo':
      return { ...state, ...action.payload }
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoPlayer({ content, maxWidth = false }) {
  const isSsr = useIsSsr()
  const [isSafariIOS, setIsSafariIOS] = useState(false)

  const { source, autoplay, altText, openFullScreen } = content

  const { breakpointLetterSize, calculatedClient, screenWidth } = useViewPortSize()

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const [state, dispatch] = useReducer(reducerFunction, {
    showVideo: false,
    dimension: {
      height: videoMap[breakpointLetterSize].height,
      width: videoMap[breakpointLetterSize].width,
    },
  })
  // use 40 to fix videoplayer padding on about us page
  const mobileWidth = maxWidth ? screenWidth : screenWidth - 40

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setIsSafariIOS(iOSSafariMobile(navigator.userAgent))
    }
  }, [isSsr])

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          dimension: {
            height: videoMap[breakpointLetterSize].height,
            width: videoMap[breakpointLetterSize].width,
          },
        },
      })
    }
  }, [calculatedClient, breakpointLetterSize])

  useEffect(() => {
    if (source) {
      setTimeout(() => {
        dispatch({
          type: 'initVideo',
          payload: {
            showVideo: true,
          },
        })
      }, 2000)
    }
  }, [source])

  useEffect(() => {
    const handleEvent = (event) => {
      if (['onPlay', 'onPause', 'onEnd'].indexOf(event.data) > -1) {
        //Tracking
      }
    }

    window.addEventListener('message', handleEvent)

    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [])

  return (
    <Grid
      data-testid="iframe-container"
      style={{
        height: state.dimension.height,
        width: isMobile ? mobileWidth : state.dimension.width,
        margin: 'auto',
      }}
      container
    >
      <div data-testid="iframe-container" className={styles['iframe-wrapper']}>
        {!state.showVideo && (
          <Skeleton
            variant="rectangular"
            width={isMobile ? mobileWidth : state.dimension.width}
            height={state.dimension.height}
          />
        )}
        {state.showVideo && (
          <Vimeo
            data-testid="vimeo-iframe"
            title={altText}
            video={source}
            height={state.dimension.height}
            width={isMobile ? mobileWidth : state.dimension.width}
            autopause
            autoplay={autoplay}
            playsInline={false}
            onReady={(player) => {
              try {
                // Only touch devices has TouchEvent
                document.createEvent('TouchEvent')
                if (openFullScreen && !isSafariIOS) {
                  setTimeout(() => {
                    player.requestFullscreen()
                  }, 500)
                }
              } catch (e) {
                return false
              }
            }}
            onPlay={(e) => {
              e.requestFullscreen()
              window.parent.postMessage('onPlay', '*')
            }}
            onPause={() => {
              window.parent.postMessage('onPause', '*')
            }}
            onEnd={() => {
              window.parent.postMessage('onEnd', '*')
            }}
          />
        )}
      </div>
    </Grid>
  )
}

export { VideoPlayer }
