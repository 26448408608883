import { Button, Typography } from '@ffn/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { GET_STARTED_ROUTE } from 'constants/navigation'

export default function HeaderCta({ className = '', 'data-testid': dataTestId, ...buttonProps }) {
  return (
    <AchieveLink
      className={className}
      href={GET_STARTED_ROUTE}
      data-testid={dataTestId}
      track={{
        nav_link_section: 'Header',
        click_type: 'Link Click',
        click_id: 'Get help now',
        track_event: 'global_header',
      }}
    >
      <Button color="primary" variant="contained" {...buttonProps}>
        <Typography variant="displayXS30" fontWeight="bold">
          Get Started
        </Typography>
      </Button>
    </AchieveLink>
  )
}
