import React from 'react'
import { Button } from '@ffn/sunbeam'
import { Typography } from 'components/Contentful'
import styles from './LinkToSection.module.scss'

export const LinkToSection = ({ content, variant, color, typographicOptions, ...restProps }) => {
  const { linkText, linkHref } = content.fields ? content.fields : content
  return (
    <Button
      color={color}
      variant={variant}
      className={styles['base-styles']}
      onClick={() => {
        const el = document.querySelector(`[data-scrolto="${linkHref}"]`)
        if (el) {
          // Adding -70px to fix element padding
          window.scrollTo({
            top: el.offsetTop - 70,
            behavior: 'smooth',
          })
        }
      }}
      {...restProps}
    >
      <Typography content={linkText} {...typographicOptions} />
    </Button>
  )
}

LinkToSection.displayName = 'LinkToSection'
