import React from 'react'
import { Button } from '@ffn/sunbeam'
import { Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import styles from './LinkButton.module.scss'

const ButtonWithRef = React.forwardRef(
  (
    {
      onClick,
      passThroughOnClick = () => {},
      label,
      variant = 'contained',
      color = 'primary',
      typographicOptions = {},
      ...restProps
    },
    // eslint complains that ref is unused, but React also complains that you
    // have to include a ref for a forwardRef render function
    ref // eslint-disable-line no-unused-vars
  ) => {
    const localClickHandler = (event) => {
      onClick(event)
      passThroughOnClick(event)
    }
    return (
      <Button
        color={color}
        variant={variant}
        className={styles['base-styles']}
        onClick={(event) => {
          localClickHandler(event)
        }}
        {...restProps}
      >
        <Typography content={label} {...typographicOptions} />
      </Button>
    )
  }
)

export const LinkButton = ({
  content,
  onClick,
  variant,
  color,
  typographicOptions,
  track,
  ...restProps
}) => {
  const { linkText, linkHref } = content.fields ? content.fields : content

  return (
    <AchieveLink
      href={linkHref}
      passHref
      track={typeof track === 'boolean' ? `${track}` : track}
      withNextLink
    >
      <ButtonWithRef
        label={linkText}
        passThroughOnClick={onClick}
        variant={variant}
        color={color}
        typographicOptions={typographicOptions}
        {...restProps}
      />
    </AchieveLink>
  )
}

ButtonWithRef.displayName = 'LinkButton'
