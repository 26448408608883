import { FEATURE_TOGGLES } from 'constants/featureToggles'

/**
 * Get a list of elements and it's LD condition
 * @param {object} featureToggleObject
 *   Feature flag object passed through pageProps - @see src/pages/_app.js
 * @returns {object}
 */
export const getFeatureDisabledElements = (featureToggleObject) => {
  if (!featureToggleObject) return {}

  const elementList = [
    FEATURE_TOGGLES.ACX_WEB_ENABLE_ALCHEMER_SURVEY,
    FEATURE_TOGGLES.ACX_WEB_ENABLE_NAV_TITLES,
  ]
  let elementToggle = {}

  Object.keys(featureToggleObject).forEach((feature) => {
    if (elementList.includes(feature)) {
      elementToggle[feature] = featureToggleObject[feature]
    }
  })

  return elementToggle
}

export const removePageFromQuery = (query) => {
  if (!query) return

  return Object.keys(query)
    .map((key) => {
      if (key == 'page') {
        return
      } else {
        return key + '=' + query[key]
      }
    })
    .filter((param) => param !== undefined)
    .join('&')
}
