export const FEATURE_TOGGLES = {
  ACX_WEB_ENABLE_ALCHEMER_SURVEY: 'acx-web-enable-alchemer-survey',
  ACX_WEB_ENABLE_NARROW_CONTAINER: 'acx-web-enable-narrow-container',
  ACX_WEB_ENABLE_NARROW_CONTENT: 'acx-web-enable-narrow-content',
  ACX_WEB_ENABLE_NAV_TITLES: 'acx-web-enable-nav-titles',
  ACX_WEB_ENABLE_UTM_PARSE: 'acx-web-fix-duplicate-query-strings',
}

export const EVENT_TYPES = {
  START: 'start',
  CONVERSION: 'conversion',
  TRACK: 'track',
}

// values should be all lower case
export const VARIATIONS = {
  CONTROL: 'control',
  VARIATION_A: 'variation_a',
  VARIATION_B: 'variation_b',
  VARIATION_C: 'variation_c',
  UNASSIGNED: 'unassigned',
  OFF: 'off',
}

export default FEATURE_TOGGLES
